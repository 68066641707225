import React from "react";
import PropTypes from "prop-types";
import { Col, Row, Typography } from "antd";
import CtaSecondary from "../Buttons/secondary-cta";
import Image from "../ImageQuerys/LargeFeaturesImages";
import platforms from "../../images/visuals/Platforms.png";
// import mega from "../../images/icons/megaphone.png";
import bg from "../../images/icons/webinar-bg.png";
import check from "../../images/svg/checkmark.svg";
import WebinarStuffStyles from "./WebinarStuffStyles";
import Heading from "../../components-v2/Base/HeadingBuilder";

const { Title } = Typography;

const WebinarStuff = () => {
  const blockList = [
    "Insider tips from SpotOn’s Restaurant Advisory Council",
    "Announcement of the SpotOn grant award winner",
    "Expert insights from America's most notable chefs",
  ];
  const leadersA = [
    {
      img: "cat.png",
      name: "Cat Cora",
    },
    {
      img: "buddy.png",
      name: "Buddy Valastro",
    },
    {
      img: "matthew.png",
      name: "Matthew Kenney",
    },
  ];
  const leadersB = [
    {
      img: "kevin.png",
      name: "Kevin Youkilis",
    },
    {
      img: "alex.png",
      name: "Alex Guarnaschelli",
    },
  ];

  return (
    <div className="webinarStuff">
      <Title level={2} className="text-center">
        Tune in to hear from restaurant industry leaders
        <span className="blue_dot">.</span>
      </Title>
      <p className="text-center">
        Watch Guy’s Restaurant Reboot to get expert insights on the future of
        the restaurant industry from some of America’s most notable chefs,
        restaurateurs, and industry leaders, including members of SpotOn’s
        Restaurant Advisory Council.
      </p>
      <Row style={{ marginTop: "5%" }} align="middle">
        <img src={bg} className="webinar-float" alt="" />
        <Col lg={12} xs={24}>
          <Row align="middle">
            <Col lg={8} xs={12}>
              {leadersA.map((lead) => (
                <div className="webinar-peeps">
                  <Image
                    imageName={lead.img}
                    style={{
                      width: "120px",
                      display: "table",
                      margin: "0 auto",
                    }}
                  />
                  <p>{lead.name}</p>
                </div>
              ))}
            </Col>
            <Col lg={8} xs={12}>
              {leadersB.map((lead) => (
                <div className="webinar-peeps">
                  <Image
                    imageName={lead.img}
                    style={{
                      width: "120px",
                      display: "table",
                      margin: "0 auto",
                    }}
                  />
                  <p>{lead.name}</p>
                </div>
              ))}
            </Col>
          </Row>
        </Col>
        <Col lg={12} xs={24}>
          <Title level={2} style={{ lineHeight: "1.5" }}>
            Behind the Counter Conversations presented by SpotOn
          </Title>
          <ul className="webinar-points">
            {blockList.map((item, i) => (
              <li key={`${i.toString()}__list`}>
                <img src={check} alt="" />
                <p>{item}</p>
              </li>
            ))}
          </ul>
        </Col>
      </Row>
      <div>
        {/* <div className="webinars-cta">
          <img src={mega} alt="" />
          <p>
            <url
              to="https://www.youtube.com/embed/QxS1mcE0pU4"
              rel="noreferrer"
              terget="_blank"
            />
            Watch the show
          </p>
        </div> */}
        <Heading className="text-center" level={3} injectionType={4}>
          Available on your favorite streaming platforms
        </Heading>
        <img className="platforms" src={platforms} alt="" />
        <CtaSecondary
          openInNewTab
          target="https://GuysRestaurantReboot.com"
          ctaTitle="and on GuysRestaurantReboot.com"
          className="platforms"
        />
      </div>

      <WebinarStuffStyles />
    </div>
  );
};

WebinarStuff.propTypes = {
  sectionData: PropTypes.objectOf(PropTypes.any),
};
WebinarStuff.defaultProps = {
  sectionData: "",
};

export default WebinarStuff;
