import React from "react";
import { viewports, colors } from "../../style-vars";

const { smOrSmaller } = viewports;
const { black200 } = colors;

const WebinarStuffStyles = () => (
  <style jsx global>{`
    .webinarStuff {
      position: relative;
      padding: 64px;
      padding-bottom: 0 !important;
    }
    @media ${smOrSmaller} {
      .webinarStuff {
        padding: 32px;
      }
    }
    .webinarStuff h4 {
      color: #000 !important;
    }
    .webinarStuff p {
      font-size: 16px;
      color: ${black200};
    }
    .webinarStuff .platforms {
      display: table;
      margin: 24px auto;
    }
    @media ${smOrSmaller} {
      .webinarStuff h2,
      .webinarStuff p {
        text-align: left !important;
      }
    }
    .webinar-cta {
      display: flex;
      background-color: #e8f0ff;
      border-radius: 32px;
      padding: 12px;
      margin: 32px auto;
      width: 35%;
      align-content: center;
      justify-content: center;
    }
    @media ${smOrSmaller} {
      .webinar-cta {
        width: 100%;
      }
    }
    .webinar-cta img {
      margin-right: 12px;
      width: 32px;
      height: 32px;
    }
    @media ${smOrSmaller} {
      .webinar-cta img {
        width: 24px;
        height: 24px;
      }
    }
    .webinar-cta h4 {
      font-size: 18px !important;
    }
    .webinar-cta p {
      font-weight: 800;
      font-size: 20px;
      margin-bottom: 0;
    }
    @media ${smOrSmaller} {
      .webinar-cta p {
        font-size: 16px;
      }
    }
    .webinar-points {
      list-style: none;
      padding: 0;
      margin-top: 64px;
      margin-bottom: 64px;
    }
    .webinar-points li {
      margin: 18px 0;
      display: flex;
      align-items: center;
      padding: 24px;
      box-shadow: 20px 10px 40px 0px #2f50a71a;
      box-shadow: 0px 2px 8px 0px #00000012;
      width: 65%;
      border-radius: 8px;
    }
    @media ${smOrSmaller} {
      .webinar-points li {
        width: 100%;
      }
    }
    .webinar-points li img {
      margin-right: 12px;
    }
    .webinar-points li p {
      width: 75%;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.2;
      margin-bottom: 0;
      color: ${black200};
    }
    @media ${smOrSmaller} {
      .webinar-points li p {
        width: 100%;
      }
    }
    .webinar-peeps {
      margin: 32px 8px;
    }
    .webinar-peeps img {
      width: 120px !important;
      height: 120px !important;
    }
    .webinar-peeps p {
      text-align: center !important;
      margin-top: 16px;
      font-weight: 800;
    }
    .webinar-float {
      position: absolute;
      right: 0;
      top: 25%;
    }
    @media ${smOrSmaller} {
      .webinar-float {
        display: none;
      }
    }
  `}</style>
);

export default WebinarStuffStyles;
